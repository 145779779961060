import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { projects } from '../../data/constants';
import styled from 'styled-components';
import {powerbiReport} from "../../data/constants"
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import './PowerBI.css'

const Container = styled.div`
  height: 100%;
  width: 100vw; 
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  gap: 12px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const ReportContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.cardBg};
  color: ${({ theme }) => theme.text};
`;

const ReportTitle = styled.h2`
color: white;
  font-size: 3rem;
  margin-bottom: 10px;
  padding-top: 10px;
  font-weight: 500;
  font-style: italic;
`;

const ReportDescription = styled.p`
text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  color: gray;
`;



const Skill = styled.div`
  width: 80%;
  height: 700px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.card};
  border: 0.1px solid #854CE6;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  border-radius: 16px;
  padding: 18px 36px;
  @media (max-width: 768px) {
    max-width: 400px;
    padding: 10px 36px;
  }
  @media (max-width: 500px) {
    max-width: 330px;
    padding: 10px 36px;
  }
`

const spanStyle = {
  padding: '20px',
  background: '#efefef',
  color: '#000000'
}

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '650px'
}

  
function PowerBI() {

  const { reportId } = useParams();
  const [project, setProject] = useState({});
  const [imagesArray, setImagesArray] = useState([])


  useEffect(() => {
    console.log("powerbiReport", powerbiReport);
    console.log("reportId", reportId);
  
    const selectedProject = powerbiReport.find((pro) => pro.pId.toString() === reportId.toString());
    console.log("selectedProject", selectedProject);
    setImagesArray(selectedProject.pImages)
    setProject(selectedProject || {});
  }, [reportId]);
  

  return (
    <Container>
      <ReportContainer>
        <Wrapper>
          <ReportTitle>{project.pTitle}</ReportTitle>
          <ReportDescription>
          {project.desc}
          </ReportDescription>
        </Wrapper>
      </ReportContainer>
<Skill>
<div className="videobhencho">
    <video controls autoplay muted loop id="backgroundVideo">
      {project.pId === 10 &&
        <source src={project.pVideo} type="video/mp4" />
      }
      {project.pId === 11 && 
      <source src={project.pVideo} type="video/mp4" />}
       {project.pId === 12 && 
      <source src={project.pVideo} type="video/mp4" />}
    </video>
</div>


</Skill>
<Skill>
<div className="slide-container">
        <Slide>
         {imagesArray.map((slideImage, index)=> (
            <div key={index}>
          <div style={{ ...divStyle, backgroundImage: `url(${slideImage.url})` }}>
                {/* <span style={spanStyle}>{slideImage.caption}</span> */}
              </div>
            </div>
          ))} 
        </Slide>
      </div>
</Skill>
    </Container>
  );
}

export default PowerBI;
