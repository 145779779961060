

import { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import './App.css';
import Education from "./components/Education";
import Experience from "./components/Experience";
import Footer from "./components/Footer";
import HeroSection from "./components/HeroSection";
import Navbar from "./components/Navbar";
import PowerBI from "./components/Powerbi/PowerBI.js";
import ProjectDetails from "./components/ProjectDetails";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import { darkTheme, lightTheme } from './utils/themes.js';

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%,30% 98%, 0 100%);
`
function App() {
  const [darkMode, setDarkMode] = useState(true);
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  console.log(openModal)
  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Router>
          <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <Body>
                <HeroSection />
                <Wrapper>
                  <Education />
                  <Skills />
                  <Projects
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                  />
                </Wrapper>
                <Wrapper>
                  <Experience />
                  {/* <Contact /> */}
                </Wrapper>
                <Footer />
                {openModal.state && (
                  <ProjectDetails
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                  />
                )}
              </Body>
            }
          />
          <Route
            path="/powerbi/:reportId"
            element={
              <Body>
                <Wrapper>
                  <PowerBI />
                </Wrapper>
              </Body>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
    // <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
    //   <Router >
    //     <Navbar />
    //     <Routes>
    //       <Route path="/" element={
    //         <Body>
    //           <HeroSection />
    //           <Wrapper>
    //           <Education />

    //             <Skills />
    //           <Projects openModal={openModal} setOpenModal={setOpenModal} />
    //           </Wrapper>
              
    //           <Wrapper>
    //             <Experience />
    //             <Contact />
    //           </Wrapper>
    //           <Footer />
    //           {openModal.state && (
    //             <ProjectDetails
    //               openModal={openModal}
    //               setOpenModal={setOpenModal}
    //             />
    //           )}
    //         </Body>
    //       } />  
    //       <Route path="/powerbi/:reportId" element={
    //         <Body>
    //           <Wrapper>
    //           <PowerBI />
    //           </Wrapper>
    //         </Body>
    //       } />
    //     </Routes>
    //   </Router>
    // </ThemeProvider>
  );
}

export default App;